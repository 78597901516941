// import React, { useRef, useState } from 'react';
// import '../css/SecondPopupForm.css'; // Assuming the styles are in SecondPopupForm.css
// import emailjs from '@emailjs/browser';

// const SecondPopupForm = ({ isVisible, closeForm }) => {
//   const form = useRef();
//   const [isSubmitting, setIsSubmitting] = useState(false); // Track submission status

//   const sendEmail = (e) => {
//     e.preventDefault();
//     setIsSubmitting(true); // Disable the form during submission

//     emailjs
//       .sendForm(
//         process.env.REACT_APP_YOUR_SERVICE_ID,
//         process.env.REACT_APP_YOUR_TEMPLATE_ID,
//         form.current,
//         process.env.REACT_APP_YOUR_PUBLIC_KEY
//       )
//       .then(
//         () => {
//           console.log('SUCCESS!');
//           setIsSubmitting(false); // Reset submission status
//           closeForm(); // Close the form after successful submission
//         },
//         (error) => {
//           console.error('FAILED...', error.text);
//           setIsSubmitting(false); // Ensure submission status resets on error
//         }
//       );
//   };

//   return (
//     <>
//       {isVisible && (
//         <div className="second-popup-overlay">
//           <div className="second-popup-form">
//             <button className="close-btn-float" onClick={closeForm}>
//               &#10006;
//             </button>
//             <h2>Express Your Interest</h2>
//             <form ref={form} onSubmit={sendEmail}>
//               <input
//                 type="text"
//                 placeholder="Name*"
//                 name="user_name"
//                 required
//                 disabled={isSubmitting}
//               />
//               <input
//                 type="email"
//                 placeholder="Email*"
//                 name="user_email"
//                 required
//                 disabled={isSubmitting}
//               />
//               <input
//                 type="tel"
//                 placeholder="Mobile*"
//                 name="user_mobile"
//                 required
//                 disabled={isSubmitting}
//               />
//               <textarea
//                 placeholder="Message"
//                 name="message"
//                 disabled={isSubmitting}
//               />
//               <button className="btn" type="submit" disabled={isSubmitting}>
//                 {isSubmitting ? 'Submitting...' : 'Submit'}
//               </button>
//             </form>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default SecondPopupForm;
import React, { useRef, useState } from 'react';
import '../css/SecondPopupForm.css';
import emailjs from '@emailjs/browser';

const SecondPopupForm = ({ isVisible, closeForm, onFormSubmit }) => {
  const form = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    emailjs
      .sendForm(
        process.env.REACT_APP_YOUR_SERVICE_ID,
        process.env.REACT_APP_YOUR_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_YOUR_PUBLIC_KEY
      )
      .then(
        () => {
          console.log('SUCCESS!');
          setIsSubmitting(false);
          onFormSubmit(); // Trigger callback after successful submission
          window.location.reload(); // Refresh the page after submission
        },
        (error) => {
          console.error('FAILED...', error.text);
          setIsSubmitting(false);
        }
      );
  };

  return (
    <>
      {isVisible && (
        <div className="second-popup-overlay">
          <div className="second-popup-form">
            <button className="close-btn-float" onClick={closeForm}>
              &#10006;
            </button>
            <h2>Express Your Interest</h2>
            <form ref={form} onSubmit={sendEmail}>
              <input
                type="text"
                placeholder="Name*"
                name="user_name"
                required
                disabled={isSubmitting}
              />
              <input
                type="email"
                placeholder="Email*"
                name="user_email"
                required
                disabled={isSubmitting}
              />
              <input
                type="tel"
                placeholder="Mobile*"
                name="user_mobile"
                required
                disabled={isSubmitting}
              />
              <textarea
                placeholder="Message"
                name="message"
                disabled={isSubmitting}
              />
              <button className="btn" type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default SecondPopupForm;
