
// import { Helmet } from "react-helmet-async";
// import Navbar from "./components/Navbar";
// import Footer from "./components/partials/Footer";
// import { useLocation } from "react-router-dom";
// import FloatingForm from "./components/FloatingForm";
// import SecondPopupForm from "./components/SecondPopupForm";
// import Tile from "./components/Tile";
// import { useState, useEffect } from "react";
// import { Routes, Route } from "react-router-dom";
// import { LandingPage } from "./components/LandingPage";
// import Privacy from "./components/partials/Privacy";
// import TermCondition from "./components/TermsCondition";
// import Comercial from "./components/Comercial";
// import Recidential from "./components/Recidential";

// function App() {
//   const [showSecondPopup, setShowSecondPopup] = useState(false);
//   const location = useLocation();

//   const handleCloseSecondPopup = () => {
//     setShowSecondPopup(false);
//   };

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setShowSecondPopup(true);
//     }, 15000);

//     return () => clearInterval(interval);
//   }, []);

//   useEffect(() => {
//     if (location.hash) {
//       const section = document.querySelector(location.hash);
//       if (section) {
//         section.scrollIntoView({ behavior: "smooth" });
//       }
//     }
//   }, [location]);

//   const getPageTitle = () => {
//     switch (location.pathname) {
//       case "/":
//         return "Find Your Dream House - Plan Bee Estate";
//       case "/privacy":
//         return "Privacy Policy - Plan Bee Estate";
//       case "/terms-condition":
//         return "Terms & Conditions - Plan Bee Estate";
//       case "/commercial":
//         return "Explore Commercial Properties in Gurgaon";
//       case "/residential":
//         return "Luxury Residential Apartments in Gurgaon";
//       default:
//         return "Plan Bee Estate - Premium Properties";
//     }
//   };

//   const getPageDescription = () => {
//     switch (location.pathname) {
//       case "/":
//         return "Discover 2BHK, 3BHK flats, luxury apartments, and penthouses in Gurgaon.";
//       case "/privacy":
//         return "Read the privacy policy for Plan Bee Estate's website.";
//       case "/terms-condition":
//         return "View the terms and conditions of using Plan Bee Estate's services.";
//       case "/commercial":
//         return "Browse commercial properties from top developers like Smartworld and M3M.";
//       case "/residential":
//         return "Find residential apartments and luxury homes in Gurgaon's prime locations.";
//       default:
//         return "Explore properties in Gurgaon with Plan Bee Estate.";
//     }
//   };

//   return (
//     <>
//       <Helmet>
//         <title>{getPageTitle()}</title>
//         <meta name="description" content={getPageDescription()} />
//         <meta property="og:title" content={getPageTitle()} />
//         <meta property="og:description" content={getPageDescription()} />
//         <meta property="og:url" content={`https://yourdomain${location.pathname}`} />
//       </Helmet>

//       <Navbar />

//       <Routes>
//         <Route path="/" element={<LandingPage />} />
//         <Route path="/privacy" element={<Privacy />} />
//         <Route path="/terms-condition" element={<TermCondition />} />
//         <Route path="/commercial" element={<Comercial />} />
//         <Route path="/residential" element={<Recidential />} />
//       </Routes>

//       <Footer />

//       <SecondPopupForm isVisible={showSecondPopup} closeForm={handleCloseSecondPopup} />
//       <FloatingForm />
//       <Tile />
//     </>
//   );
// }

// export default App;
import { Helmet } from "react-helmet-async";
import Navbar from "./components/Navbar";
import Footer from "./components/partials/Footer";
import { useLocation } from "react-router-dom";
import FloatingForm from "./components/FloatingForm";
import SecondPopupForm from "./components/SecondPopupForm";
import Tile from "./components/Tile";
import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { LandingPage } from "./components/LandingPage";
import Privacy from "./components/partials/Privacy";
import TermCondition from "./components/TermsCondition";
import Comercial from "./components/Comercial";
import Recidential from "./components/Recidential";

function App() {
  const [showSecondPopup, setShowSecondPopup] = useState(false);
  const location = useLocation();

  // Check if the user has already submitted the form
  useEffect(() => {
    const isFormSubmitted = localStorage.getItem("formSubmitted") === "true";
    if (!isFormSubmitted) {
      const interval = setInterval(() => {
        setShowSecondPopup(true);
      }, 20000);

      return () => clearInterval(interval);
    }
  }, []);

  const handleCloseSecondPopup = () => {
    setShowSecondPopup(false);
  };

  const handleFormSubmission = () => {
    localStorage.setItem("formSubmitted", "true"); // Store form submission status
    setShowSecondPopup(false); // Close the popup
  };

  useEffect(() => {
    if (location.hash) {
      const section = document.querySelector(location.hash);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const getPageTitle = () => {
    switch (location.pathname) {
      case "/":
        return "Find Your Dream House - Plan Bee Estate";
      case "/privacy":
        return "Privacy Policy - Plan Bee Estate";
      case "/terms-condition":
        return "Terms & Conditions - Plan Bee Estate";
      case "/commercial":
        return "Explore Commercial Properties in Gurgaon";
      case "/residential":
        return "Luxury Residential Apartments in Gurgaon";
      default:
        return "Plan Bee Estate - Premium Properties";
    }
  };

  const getPageDescription = () => {
    switch (location.pathname) {
      case "/":
        return "Discover 2BHK, 3BHK flats, luxury apartments, and 4BHK and 5BHK penthouses in Gurgaon.";
      case "/privacy":
        return "Read the privacy policy for Plan Bee Estate's website.";
      case "/terms-condition":
        return "View the terms and conditions of using Plan Bee Estate's services.";
      case "/commercial":
        return "Browse commercial properties from top developers like Smartworld and M3M.";
      case "/residential":
        return "Find residential apartments and luxury homes in Gurgaon's prime locations.";
      default:
        return "Explore properties in Gurgaon with Plan Bee Estate.";
    }
  };

  return (
    <>
      <Helmet>
        <title>{getPageTitle()}</title>
        <meta name="description" content={getPageDescription()} />
        <meta property="og:title" content={getPageTitle()} />
        <meta property="og:description" content={getPageDescription()} />
        <meta property="og:url" content={`https://yourdomain${location.pathname}`} />
      </Helmet>

      <Navbar />

      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms-condition" element={<TermCondition />} />
        <Route path="/commercial" element={<Comercial />} />
        <Route path="/residential" element={<Recidential />} />
      </Routes>

      <Footer />

      <SecondPopupForm
        isVisible={showSecondPopup}
        closeForm={handleCloseSecondPopup}
        onFormSubmit={handleFormSubmission} // Pass callback to handle form submission
      />
      <FloatingForm />
      <Tile />
    </>
  );
}

export default App;
