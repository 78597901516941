import React, { useRef, useState } from "react";
import "../css/Residential.css";
import { residentialPropertyData } from "../constants/residentialPropertyData";
import { useNavigate } from "react-router-dom";

function Recidential() {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProperties, setFilteredProperties] = useState(residentialPropertyData);
  const [showMore, setShowMore] = useState({});
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [errorMessage, setErrorMessage] = useState(""); // Track error message state

  const navigate = useNavigate();
  const navCheckRef = useRef();
  const searchInputRef = useRef();

  const handleNavItemClick = (path, hash = "") => {
    navigate(`${path}${hash}`, { replace: true });

    if (navCheckRef.current) {
      navCheckRef.current.checked = false;
    }

    window.scrollTo(0, 0); // Scroll to top
  };

  const toggleShowMore = (id) => {
    setShowMore((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const openModal = (property) => {
    setSelectedProperty(property);
  };

  const closeModal = () => {
    setSelectedProperty(null);
  };

  const handleSearch = () => {
    if (searchQuery.trim() === "") {
      setErrorMessage("Please enter a property to search.");
      setFilteredProperties(residentialPropertyData); // Reset to all properties
    } else {
      const filtered = residentialPropertyData.filter(
        (property) =>
          property.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          property.description.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredProperties(filtered);
      setErrorMessage(""); // Clear error after valid input
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault(); // Prevent form submission from refreshing the page
    handleSearch(); // Execute search logic

    if (searchInputRef.current) {
      searchInputRef.current.blur(); // Collapse mobile keyboard
    }
  };

  return (
    <div>
      <div className="search-page">
        <div className="search-banner">
          <h1>PlanBeeEstate</h1>
          <img src="logo.png" alt="PlanBeeEstate Logo" />
        </div>

        <form onSubmit={handleFormSubmit}>
          <input
            type="text"
            placeholder="Search properties..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            ref={searchInputRef}
          />

          <div className="buttons">
            <button type="submit" className="btn">Search</button>
            <button
              type="button"
              className="btn"
              onClick={() => handleNavItemClick("/commercial")}
            >
              See Commercial
            </button>
          </div>
        </form>

        {/* Display error message if search input is empty */}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>

      <div className="properties-container">
        {filteredProperties.map((property) => (
          <div
            className="property-card"
            key={property.id}
            onClick={() => openModal(property)}
          >
            <img src={property.image} alt={`Property Image ${property.id}`} />
            <div className="property-details">
              <h3>{property.name}</h3>
              <p>
                {showMore[property.id]
                  ? property.description
                  : `${property.description.substring(0, 80)}...`}{" "}
                {property.description.length > 80 && (
                  <span
                    className="show-more-toggle"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent modal from opening
                      toggleShowMore(property.id);
                    }}
                    style={{ color: "#2a9d8f", cursor: "pointer" }}
                  >
                    {showMore[property.id] ? "Show Less" : "Show More"}
                  </span>
                )}
              </p>
              <span className="price">{property.price}</span>
            </div>
          </div>
        ))}
      </div>

      {selectedProperty && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-modal" onClick={closeModal}>
              X
            </button>
            <img src={selectedProperty.image} alt={selectedProperty.name} />
            <h2>{selectedProperty.name}</h2>
            <p>{selectedProperty.description}</p>
            <span className="price">{selectedProperty.price}</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default Recidential;
