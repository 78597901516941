
import React, { useRef, useState } from "react";
import "../css/FloatingForm.css";
import emailjs from "@emailjs/browser";

const FloatingForm = () => {
  const [isMinimized, setIsMinimized] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // Track submission status
  const form = useRef();

  const minimizeForm = () => setIsMinimized(true);
  const maximizeForm = () => setIsMinimized(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Disable the form during submission

    emailjs
      .sendForm(
        process.env.REACT_APP_YOUR_SERVICE_ID,
        process.env.REACT_APP_YOUR_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_YOUR_PUBLIC_KEY
      )
      .then(
        () => {
          console.log("SUCCESS!");
          setIsSubmitting(false); // Reset submission status
          minimizeForm(); // Minimize the form after successful submission
        },
        (error) => {
          console.error("FAILED...", error.text);
          setIsSubmitting(false); // Reset status if there is an error
        }
      );
  };

  return (
    <div className="floating-form">
      {!isMinimized && (
        <div className="form-content">
          <button className="close-btn" onClick={minimizeForm}>
            &#10006;
          </button>
          <h2>Express Your Interest</h2>
          <form ref={form} onSubmit={sendEmail}>
            <input
              type="text"
              placeholder="Name*"
              name="user_name"
              required
              disabled={isSubmitting}
            />
            <input
              type="email"
              placeholder="Email*"
              name="user_email"
              required
              disabled={isSubmitting}
            />
            <input
              type="tel"
              placeholder="Mobile*"
              name="user_mobile"
              required
              disabled={isSubmitting}
            />
            <textarea
              placeholder="Message"
              name="message"
              disabled={isSubmitting}
            />
            <button type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </form>
        </div>
      )}
      {isMinimized && (
        <div className="minimized-icon" onClick={maximizeForm}>
          <i className="fa-regular fa-envelope icon"></i>
        </div>
      )}
    </div>
  );
};

export default FloatingForm;
