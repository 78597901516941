// src/components/PrimeFocus.jsx
import React from "react";
import "./../css/PrimeFocus.css"; // Import specific CSS for this component

const PrimeFocus = () => {
  return (
    <section className="prime-focus">
      {/* <h2>PRESENT YOU</h2> */}
      <h1>Our Prime Focus</h1>
      <div className="focus-cards">
        <div className="focus-card">
          <span className="icon">💬</span>
          <h3>Quality Service</h3>
          <p>
            Plan Bee Estates endeavors to provide the best quality service for its
            customers to make maximum profits.
          </p>
        </div>
        <div className="focus-card">
          <span className="icon">✏️</span>
          <h3>Research & Learning</h3>
          <p>
            A team with a good understanding of the real estate market keeps
            on learning new trends & innovations in the field.
          </p>
        </div>
        <div className="focus-card">
          <span className="icon">📦</span>
          <h3>Customer Satisfaction</h3>
          <p>
            Our ultimate goal is to keep our customers happy by providing the
            best property within their budget.
          </p>
        </div>
      </div>
    </section>
  );
};

export default PrimeFocus;
