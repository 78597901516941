import { useNavigate } from "react-router-dom";
import "../css/Home.css";
import { useRef } from "react";

const Home = () => {
  const navigate = useNavigate();
  const navCheckRef = useRef();
  const handleNavItemClick = (path, hash = "") => {
    navigate(`${path}${hash}`, { replace: true });

    if (navCheckRef.current) {
      navCheckRef.current.checked = false;
    }

    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };
  return (
    <section class="home" id="home">
      <div class="main-box">
        <div className="content">
        <h2>Welcome to PlanBee Estate,</h2>
          <h1>Your Search For Dream Property Begins Here</h1>
          <p>
            Discover the perfect home for your family with PlanBeeEstate. We
            offer the best listings for sale and rent in your desired location.
          </p>
        </div>
      </div>
      <div className="content-btn">
      <button className="btn" onClick={()=>{handleNavItemClick('/residential')}}>
      Browse Properties
      </button>
        {/* <a href="#Projects" className="btn">
          Browse Properties
        </a> */}
        {/* <button href="#Projects" className="btn">Contact Us</button> */}
      </div>
    </section>
  );
};

export default Home;
