// src/components/CustomerTestimonials.jsx
import React from "react";
import "./../css/CustomerTestimonials.css"; // CSS for this section

const testimonials = [
  {
    quote:
      "Hi team, I really appreciate the services that are provided by Plan Bee Estates. The executives are really very kind and humble and always ready to help the client. I am so satisfied with their services that I am just free from all tensions and problems that come across while purchasing the dream home of mine.",
    name: "- Kumar Yadav",
  },
  {
    quote:
      "I have met with an executive of Plan Bee Estates. It was nice meeting with him and he gave very nice 'Comparison and Growth of my Investment'. Overall I am completely satisfied with the service of Plan Bee Estates as well as the Executive. Thanks!",
    name: "- Surender Singh",
  },
  {
    quote:
      "It was quite a very good experience with Plan Bee Estates. The salesperson I met explained everything nicely. He was very responsive. Overall it was very nice.",
    name: "- Bijender Singh",
  },
];

const CustomerTestimonials = () => {
  return (
    <section className="customer-testimonials">
      <h2>Customer Says</h2>
      <div className="testimonials-container">
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-card">
            <span className="quote-icon">“</span>
            <p>{testimonial.quote}</p>
            <h3>{testimonial.name}</h3>
          </div>
        ))}
      </div>
    </section>
  );
};

export default CustomerTestimonials;
