import React from "react";
import "./../css/About.css"; // Import the CSS for styling

const AboutUs = () => {
  return (
    <section className="about-us" id="About">
      <div className="about-us-content">
        <h1>PLAN BEE ESTATE</h1>
        <p>
          Welcome to Plan Bee Estate Pvt Ltd - your trusted partner in finding
          the perfect home in Gurgaon. We specialize in offering a premium
          selection of 2BHK and 3BHK flats, 4 BHK 5BHK penthouse, luxury apartments, and penthouses in
          Gurgaon's top real estate developments. Our exclusive listings include
          properties from renowned projects such as Smartworld , Signature
          Global, Trehan, and M3M Golf Hills.
        </p>
        <p>
          Whether you're seeking a cozy family home or a spacious luxury
          retreat, our curated real estate portfolio caters to every lifestyle.
          With Plan Bee Estate, you'll experience upscale living in the heart of
          Gurgaon—a city known for its growth, infrastructure, and vibrant
          community.
        </p>
        <p>
          We are committed to delivering exceptional customer satisfaction and
          transparency throughout the property-buying journey. From the initial
          search to final transactions, we provide end-to-end real estate
          solutions. Explore top properties in Gurgaon with Plan Bee Estate Pvt
          Ltd and discover unmatched living experiences in one of India's most
          dynamic cities.
        </p>
        <p>
          Our real estate agency is a company that helps clients buy, sell, or
          rent properties. Real estate agencies can help clients navigate the
          complex world of real estate by providing them with access.
        </p>
      </div>
      <div className="about-us-image">
        <img src="houses/reach3roads.png" alt="Luxury apartments in Gurgaon" />
      </div>
    </section>
  );
};

export default AboutUs;
