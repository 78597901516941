export const propertyData = [
  {
    id: 1,
    name: "M3M Route 65",
    description: "A 1 km long high-street retail development located in Sector 65, Gurgaon. Offers a wide range of retail shops and commercial spaces with road-facing access.",
    price: "₹80 Lakh Onwards",
    image: "houses/route65.jpg",
    type: "commercial",
    location: "Gurgaon Sector 65",
  },
  {
    id: 2,
    name: "M3M Capital Walk",
    description: "Strategically located in Sector 113, this premium development offers retail shops and office spaces with a high-end urban feel, ideal for commercial investments.",
    price: "₹1.5 Cr Onwards",
    image: "houses/capitalwalk.jpg",
    type: "commercial",
    location: "Gurgaon Sector 113",
  },
  {
    id: 3,
    name: "Emaar Business District EBD 83 SCO Plots",
    description: "Offering vibrant shop-cum-office (SCO) spaces with amenities such as performance plazas, ample parking, and outdoor cafés, located in Sector 65.",
    price: "₹3.6 Cr Onwards",
    image: "houses/ebd83.jpg",
    type: "commercial",
    location: "Gurgaon Sector 65",
  },
  {
    id: 4,
    name: "M3M Urbana Premium/Urbana",
    description: "This ready-to-move office space in Sector 65, Gurgaon, offers modern infrastructure, making it an ideal choice for startups and businesses looking for premium office locations.",
    price: "₹1 Cr Onwards",
    image: "houses/urbanapark.jpg",
    type: "commercial",
    location: "Gurgaon Sector 65",
  },
  {
    id: 5,
    name: "M3M Jewel MG Road",
    description: "A premium retail and office space located on MG Road, Sector 25, Gurgaon. The project offers state-of-the-art design with excellent connectivity to metro stations.",
    price: "₹1.25 Cr Onwards",
    image: "houses/jewelmg.jpg",
    type: "commercial",
    location: "Gurgaon Sector 25",
  },
  {
    id: 6,
    name: "Reach 3 Roads Sector 70",
    description: "An iconic retail and office space in Sector 70, Gurgaon. Ideal for businesses looking for visibility and accessibility in a growing commercial hub.",
    price: "₹1.7 Cr Onwards",
    image: "houses/reach3roads.png",
    type: "commercial",
    location: "Gurgaon Sector 70",
  },
  {
    id: 7,
    name: "Elan Epic",
    description: "Located in Sector 70, Gurgaon, Elan Epic is a luxurious commercial space offering retail, dining, and entertainment options, including a multi-floor atrium with a water body.",
    price: "₹1.25 Cr Onwards",
    image: "houses/elanepic.jpeg",
    type: "commercial",
    location: "Gurgaon Sector 70",
  },
  {
    id: 8,
    name: "JOY Central",
    description: "An upcoming high-street retail project in Sector 65, Gurgaon, featuring shops, restaurants, and entertainment zones, designed to offer a holistic experience for visitors.",
    price: "₹50 Lakh Onwards",
    image: "houses/joycentral.jpg",
    type: "commercial",
    location: "Gurgaon Sector 65",
  },
  {
    id: 9,
    name: "JOY Gallery",
    description: "A retail-centric project located in Sector 66, Gurgaon. JOY Gallery offers premium commercial spaces with luxury brands and fine-dining restaurants.",
    price: "₹1.3 Cr Onwards",
    image: "houses/joygallery.jpeg",
    type: "commercial",
    location: "Gurgaon Sector 66",
  },
  {
    id: 10,
    name: "ELAN MIRACLE",
    description: "A commercial marvel located in Sector 84, Gurgaon, offering world-class retail shops, a multiplex, and food court with modern infrastructure.",
    price: "₹1.07 Cr Onwards",
    image: "houses/elanmiracle.jpg",
    type: "commercial",
    location: "Gurgaon Sector 84",
  },
  {
    id: 11,
    name: "M3M SCO Plots Sec 113",
    description: "Strategically located on Dwarka Expressway, Sector 113, these SCO plots provide the ideal space for building your retail or commercial office space.",
    price: "₹5.5 Cr Onwards",
    image: "houses/sco113.jpeg",
    type: "commercial",
    location: "Gurgaon Sector 113",
  },
  {
    id: 12,
    name: "AMEYA SAPPHIRE 57",
    description: "Located in Sector 57, Gurgaon, Ameya Sapphire 57 offers premium retail and office spaces with state-of-the-art infrastructure for businesses.",
    price: "₹90 Lakh Onwards",
    image: "houses/sapphire57.jpeg",
    type: "commercial",
    location: "Gurgaon Sector 57",
  },
];
